/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import logoBlock from '../../logo-emblem.png'; // Adjust this path to your actual image path
import logoBlockDark from '../../logo-emblem-metal.png';

import LoginForm from '../../components/LoginForm/LoginForm';
import UserSignupForm from '../../components/UserSignupForm/UserSignupForm';
import EventListPreview from '../../components/EventListPreview/EventListPreview';

import C2AMessage from '../../components/C2AMessage';

import './VibeCheck.css'
import { useNavigate } from 'react-router-dom';

import config from '../../config';

const url = config.createApiUrl('identity', `/`, config.URL_TYPES.BASE);

const GetStarted = () => {
  const [isNewUser, setNewUser] = useState(false);

  const [isDarkMode, setIsDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener('change', handleChange);

    return () => darkModeMediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleUser = () => {
    setNewUser(!isNewUser);
  };

  const loadForgotPage = () => {
    window.location.href = `${url}forgot-password`;
  }
  
  return (
    <div className='getting-started-page-vibe-check'>
      <div className="logo_wrapper">
        <img className="logo-block-vibe-check" src={isDarkMode ? logoBlockDark : logoBlock} alt="Revibe Logo" />
      </div>
      
      <h4>We're exploring a new vibe</h4>
      <h5 style={{padding: "2rem 0"}}>
        <span>
          Do you have vibes to share?
        </span>
        <a className='button' href="mailto:support@revibe.life">Send us an email</a>
      </h5>
      <h6>
        Reach out to share your thoughts and ideas
      </h6>
    </div>
  );
};

export default GetStarted;
