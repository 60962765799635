/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import './App.css';

import StyleGuide from './pages/StyleGuide/StyleGuide';
import Resources from './pages/Resources/Resources';
import Search from './pages/Search/Search';
import LegalPolicies from './pages/LegalPolicies/LegalPolicies';
import DataControl from './pages/DataControl/DataControl';
import FAQ from './pages/FAQ/FAQ';
import Mission from './pages/Mission/Mission';
import EventPage from './pages/EventPage';
import GetStarted from './pages/GetStarted';

import { OrganizationProvider } from './context/OrganizationContext';
import OurGoodVibes from './pages/OurGoodVibes/OurGoodVibes';
import PersonalCalendar from './pages/PersonalCalendar';
import EmailLanding from './pages/EmailLanding';
import Tutorial from './pages/Tutorial/Tutorial';
import Rules from './pages/Rules/Rules';

// Budget Microapp // (IDEA: Create a AWS Cloudfront or Web Component for encapsulating )
import BudgetMainPage from './pages/BudgetMainPage';
import BudgetQuickStart from './pages/BudgetQuickStart';
import BudgetEditPage from './pages/BudgetEditPage';

// Stats
import Stats from './pages/Stats';

// Releases
import Releases from './pages/Releases';

// Query Engine
import QueryEngine from './pages/QueryEngine';

// Event Management
import EventManagement from './pages/EventManagement';

// Landing Page for Pause of Revibe
import VibeCheck from './pages/VibeCheck';

// Create a custom hook to encapsulate the logic for determining if the nav should be hidden
const useHideNav = (rootPaths) => {
  const location = useLocation(); // Hook to get the current location object
  const hideNav = location.pathname === '/auth' || rootPaths.some(path => location.pathname.startsWith(path));
  return hideNav;
};

const AppContent = () => {
  const rootPaths = ['/goodvibes', '/']; // Add other root paths you want to check
  const hideNav = useHideNav(rootPaths);

  const navigate = useNavigate();
  const location = useLocation();

  // Initialize state based on local storage, assume false if nothing is set
  const [hasAccepted, setHasAccepted] = useState(() => {
    return JSON.parse(localStorage.getItem("visited_good_vibes") || false);
  });

  const [redirectPath, setRedirectPath] = useState(null);

  useEffect(() => {
    // Redirect if the user has not accepted the terms and is not already on the Good Vibes or auth pages
    if (!hasAccepted && location.pathname !== '/' && location.pathname !== '/goodvibes' && location.pathname !== '/auth') {
      // Save current pathname to redirectPath state
      setRedirectPath(location.pathname);
      navigate('/goodvibes');
    }
  }, [hasAccepted, location, navigate]);

  useEffect(() => {
    const adjustHeight = () => {
      const mainElement = document.getElementById('main');
      if (mainElement) {
        const navHeight = hideNav ? 0 : 3 * 16; // 3rem to pixels
        mainElement.style.height = `${window.innerHeight - navHeight}px`;
      }
    };

    adjustHeight();
    window.addEventListener('resize', adjustHeight);
    
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, [hideNav]);

  return (
    <div style={{ display: "flex", width: "100vw" }}>
      <div id="main">
        <div className='content'>
          {/* Your Routes here */}
          <Routes>
            <Route path="/stay-tuned" element={<EmailLanding />} />
            <Route path="/auth" element={<GetStarted />} />
            <Route path="/styles" element={<StyleGuide />} />
            <Route path="/goodvibes" element={<OurGoodVibes setHasAccepted={setHasAccepted} redirectPath={redirectPath} />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/search" element={
                <OrganizationProvider>
                  <Search />
                </OrganizationProvider>
            } />
            <Route path="/mission" element={<Mission />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="/policies" element={<LegalPolicies />} />
            <Route path="/data-control" element={<DataControl />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/event/:eventID" element={
                <OrganizationProvider>
                  <EventPage />
                </OrganizationProvider>
            } />
            {/* 
            <Route path="/" element={
                <OrganizationProvider>
                    <PersonalCalendar />
                </OrganizationProvider>
            } />
            */}
            <Route path="/" element={
                <OrganizationProvider>
                    <VibeCheck />
                </OrganizationProvider>
            } />
            <Route path="/budget" element={<BudgetQuickStart />} />
            <Route path="/budget-main" element={<BudgetMainPage />} />
            <Route path="/budget-edit" element={<BudgetEditPage />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/releases" element={<Releases />} />
            <Route path="/query" element={<QueryEngine />} />
            <Route path="/manage" element={<EventManagement />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  console.log('Events')
  
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
